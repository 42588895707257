import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";
//import classes from "./Auth.css";
import * as actions from "../../store/actions/index";
import { updateObject, checkValidity } from "../../shared/utility";
import "./Auth.css";
import $ from "jquery";
const logoIcon = require("../../styles/img/logo-2_new.png");
let width = $(document).width();

class Auth extends Component {
  state = {
    controls: {
      email: {
        elementType: "input",
        elementConfig: {
          type: "text",
          autoFocus: localStorage.getItem("LoginID") != null ? false : true,
          id: "email",
          //placeholder: "Mail Address"
        },
        value: localStorage.tempuser !== "" && localStorage.tempuser !== undefined?localStorage.tempuser: "",
        validation: {
          required: true,
          isEmail: true,
        },
        valid: false,
        touched: false,
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          autoFocus: localStorage.getItem("LoginID") != null ? true : false,
          //placeholder: "Password"
        },
        value: localStorage.tempuser !== "" && localStorage.tempuser !== undefined?localStorage.tempuser: "",
        validation: {
          required: true,
          minLength: 6,
        },
        valid: false,
        touched: false,
      },
    },
    isKhidmatGuzar: true,
    isRememberMe: false,
    validationerror: "",
    isCleared: false,
    loading: false,
  };

  componentDidMount() {
    if(localStorage.temptoken !=='' && localStorage.temptoken !== undefined)
    {
    this.ssosubmitHandler();
    }
    if (this.props.authRedirectPath !== "/") {
      this.props.onSetAuthRedirectPath();
    }
    if (localStorage.getItem("LoginID") != null) {
      this.setState({ isRememberMe: true });
      const updatedControls = updateObject(this.state.controls, {
        email: updateObject(this.state.controls["email"], {
          value: localStorage.getItem("LoginID"),
          valid: checkValidity(
            localStorage.getItem("LoginID")
            //,this.state.controls["email"].validation
          ),
          touched: true,
        }),
      });
      this.setState({ controls: updatedControls });
    }
  }

  inputChangedHandler = (event, controlName) => {
    const updatedControls = updateObject(this.state.controls, {
      [controlName]: updateObject(this.state.controls[controlName], {
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          this.state.controls[controlName].validation
        ),
        touched: true,
      }),
    });
    this.setState({ controls: updatedControls });
  };

  ssosubmitHandler = () => {
    //event.preventDefault();
    this.setState({ isCleared: false });
    if (this.state.controls["email"].value.trim() === "") {
      this.setState({ validationerror: "Username/ITS required" });
    } else if (this.state.controls["password"].value.trim() === "") {
      this.setState({ validationerror: "Password required" });
    } else {
      this.setState({ validationerror: "", loading: true });
      this.props.onAuth(
        this.state.controls.email.value,
        this.state.controls.password.value,
        this.state.isRememberMe
      );
    }
  };

  submitHandler = (event) => {
    event.preventDefault();
    this.setState({ isCleared: false });
    if (this.state.controls["email"].value.trim() === "") {
      this.setState({ validationerror: "Username/ITS required" });
    } else if (this.state.controls["password"].value.trim() === "") {
      this.setState({ validationerror: "Password required" });
    } else {
      this.setState({ validationerror: "", loading: true });
      this.props.onAuth(
        this.state.controls.email.value,
        this.state.controls.password.value,
        this.state.isRememberMe
      );
    }
  };

  checkboxHandler = () => {
    this.setState((prevState) => {
      return { isRememberMe: !prevState.isRememberMe };
    });
  };
  clearDataHandler = () => {
    const cleardata = updateObject(this.state.controls, {
      email: updateObject(this.state.controls["email"], {
        value: "",
        touched: false,
      }),
      password: updateObject(this.state.controls["password"], {
        value: "",
        touched: false,
      }),
    });
    this.setState({
      controls: cleardata,
      isKhidmatGuzar: true,
      validationerror: "",
      isCleared: true,
    });
    document.getElementById("email").focus();
  };

  render() {
    let AuthLoading = <Spinner />;
    let errorMessage = null;
    if (this.state.validationerror.trim() !== "") {
      errorMessage = <p className="error">{this.state.validationerror}</p>;
    }
    // if(this.state.loading == true)
    // {
    //   this.setState({loading:this.props.loading})
    // }
    if (
      this.props.error &&
      this.state.validationerror.trim() === "" &&
      !this.state.isCleared
    ) {
      errorMessage = <p className="error">{this.props.error}</p>;
    }

    let authRedirect = null;
    if (this.props.isAuthenticated) {
      authRedirect = <Redirect to={this.props.authRedirectPath} />;
    }

    return (
      <div className="loginPage h-100">
        <div className=" d-flex align-items-center  h-100">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className={width > 600 ? "col-4":"col-8"}>
                <div className="wrapper">
                  <div className="logoImage ">
                    <div className="d-flex justify-content-center">
                      <img
                        className="align-self-center mr-2"
                        src={logoIcon}
                        alt=""
                      />
                    </div>
                  </div>

                  {authRedirect}

                  {this.props.loading ? AuthLoading : null}
                  <form className="formWrapper" onSubmit={this.submitHandler}>
                    {errorMessage}
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">ITS</label>
                      <Input
                        key={"email"}
                        classType="form-control usrimg"
                        elementType={this.state.controls["email"].elementType}
                        elementConfig={
                          this.state.controls["email"].elementConfig
                        }
                        value={this.state.controls["email"].value}
                        invalid={!this.state.controls["email"].valid}
                        shouldValidate={this.state.controls["email"].validation}
                        touched={this.state.controls["email"].touched}
                        changed={(event) =>
                          this.inputChangedHandler(event, "email")
                        }
                      />
                    </div>
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <Input
                        key={"password"}
                        classType="form-control pswdimg"
                        elementType={
                          this.state.controls["password"].elementType
                        }
                        elementConfig={
                          this.state.controls["password"].elementConfig
                        }
                        value={this.state.controls["password"].value}
                        invalid={!this.state.controls["password"].valid}
                        shouldValidate={
                          this.state.controls["password"].validation
                        }
                        touched={this.state.controls["password"].touched}
                        changed={(event) =>
                          this.inputChangedHandler(event, "password")
                        }
                      />
                    </div>
                    <div className=" d-flex">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                          checked={this.state.isRememberMe}
                          onChange={this.checkboxHandler}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheck1"
                        >
                          Remember me
                        </label>
                      </div>
                      {/* <div className="ms-auto">
                                        <a href="#">Forgot Password</a>
                                    </div> */}
                    </div>
                    {/* {form} */}
                    <div className="text-center mt-4">
                      <Button classType="btn">Login</Button>
                      <button
                        type="button"
                        onClick={this.clearDataHandler}
                        className="btn btn-bordered ml-2"
                      >
                        Clear
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <footer>
              <nav className="navbar fixed-bottom navbar-light ">
                <div>
                  Copyright © 2021 Sigatul Mazaraat - All rights reserved
                </div>
                <div>Version - {global.appVersion}</div>
                <div className="">
                  Designed &amp; Developed by{" "}
                  <a
                    href="https://babjisoftech.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-underline"
                  >
                    Babji Softech
                  </a>
                </div>
              </nav>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    //buildingBurger: state.burgerBuilder.building,
    authRedirectPath: state.auth.authRedirectPath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password, isRememberMe) =>
      dispatch(actions.auth(email, password, isRememberMe)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
