import React from "react";
//import CustomScroll from "react-custom-scroll";
import { NavLink } from "react-router-dom";
import SwitchComponent from "../../../App";
import "./LeftNavigation.css";
import Aux1 from "../../../hoc/Aux1/Aux1";
import NavigationItems from "../NavigationItems/NavigationItems";
import Tippy from '@tippy.js/react';
let Userimg = require("../../../styles/img/user.png");

const leftNavigation = (props) => {
  return (
    <Aux1>
      <aside className="sidenav">
        <ul className="navbar-nav ">
          <li className="nav-item dropdown ">
            <div
              className="nav-link dropdown-toggle"
              id="userDropdown"
              >
              {/* <img className="img-profile rounded-circle" src={Userimg} /> */}
              <Tippy placement="right-start" 
                content={<img alt="" src={"data:image/png;base64," +  localStorage.photo} onError={(e) => e.target.src=Userimg} width="110" height = "110" />}>
              <img alt="" className="img-profile rounded-circle" src={"data:image/png;base64," +  localStorage.photo} onError={(e) => e.target.src=Userimg}/>
              </Tippy>
              <div className=" align-middle usernameAndRole">
                <span className="ml-2  d-block userName mr-1">
                  {localStorage.username}
                </span>
                <span className="ml-2  d-block role">
                  {localStorage.role2}
                </span>
              </div>
            </div>
          </li>
          {/* <li className="nav-item dropdown ">
            <a className="nav-link dropdown-toggle" style={{cursor:'pointer'}} id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img className="img-profile rounded-circle" src={Userimg} />
              <div className="d-inline-flex flex-column align-middle usernameAndRole">
              <span className="ml-2  d-block userName">{localStorage.username}</span>
              <span className="ml-2  d-block small role">{localStorage.role2}</span>
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-right  animated-growing" aria-labelledby="userDropdown">

            <NavLink className="dropdown-item" to="/logout">Logout</NavLink>
                   
            </div>
          </li> */}
        </ul>
        <div className="logoutNav">
        <NavLink className="logoutNav" style={{cursor: 'pointer'}} to="/logout">Logout</NavLink> 
                </div>
        {/* <div className="crown topCrown" /> */}

        <div className="navContent">
          <ul className="list-unstyled">
            <NavigationItems isAuthenticated={props.isAuth} />
          </ul>
        </div>

        <div className="crown bottomCrown" />
      </aside>

      {SwitchComponent}
    </Aux1>
  );
};

export default leftNavigation;
